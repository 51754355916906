import React from "react"
// Icons
import { AiOutlineMail } from "react-icons/ai"
// Assets
import { InstagramIcon, TikTokIcon, FacebookIcon } from "../../images/socials"

const Socials = () => {
  return (
    <div className="flex items-center  text-center mt-4 flex-wrap justify-center">
      <a
        className="p-2 transform transition duration-200 hover:-translate-y-0.5"
        href="https://instagram.com/lilicadez"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon size="h-9 w-9" />
      </a>
      <a
        className="p-2 transform transition duration-200 hover:-translate-y-0.5"
        href="https://www.tiktok.com/@cadezlili"
        target="_blank"
        rel="noreferrer"
      >
        <TikTokIcon size="h-9 w-9" />
      </a>
      <a
        className="p-2 transform transition duration-200 hover:-translate-y-0.5"
        href="mailto:business@lilicadez.com"
      >
        <AiOutlineMail size={32} />
      </a>
      {/* <a
        className="p-2 transform transition duration-200 hover:-translate-y-0.5"
        href="https://www.facebook.com/shoplilicadezpresets"
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon size="h-9 w-9" />
      </a> */}
    </div>
  )
}

export default Socials
