import React from "react"
import { Link } from "gatsby"

const Grid = () => {
  return (
    <div className="grid grid-cols-1 gap-6">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://influspace.agency/creators/products/the-creator-mobile-video-luts-filters"
        className="border-2 border-primary-110  bg-primary-30 text-center p-4 font-medium transition duration-150 hover:bg-primary-100 text-sm sm:text-base"
      >
        <strong>NOVO:</strong> The Creator Video LUTS Paket{" "}
        <span role="img" aria-label="Camera">
          🎥
        </span>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://free-presets.influspace.agency"
        className="border-2 border-primary-110 bg-primary-30 text-center p-4 font-medium transition duration-150 hover:bg-primary-100 text-sm sm:text-base"
      >
        Brezplačen Preset Paket - 8 Mobile Presets{" "}
        <span role="img" aria-label="Heart">
          ❤️
        </span>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://reels.influspace.agency"
        className="border-2 border-primary-110 bg-primary-30 text-center p-4 font-medium transition duration-150 hover:bg-primary-100 text-sm sm:text-base"
      >
        Spremeni Svoje Fotke v IG Reelse{" "}
        <span role="img" aria-label="Stars">
          🤩
        </span>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://influspace.agency/creators/creators-secrets"
        className="border-2 border-primary-110 bg-primary-30 text-center p-4 font-medium transition duration-150 hover:bg-primary-100 text-sm sm:text-base"
      >
        Creators Secrets{" "}
        <span role="img" aria-label="Stars">
          🤫
        </span>
      </a>
      {/* <Link
        to="/master-bundle"
        className="border-2 border-primary-110 bg-primary-30 text-center p-4 font-bold transition duration-150 hover:bg-primary-100 text-sm sm:text-base"
      >
        <span role="img" aria-label="Shock">
          🤯
        </span>{" "}
        MASTER BUNDLE PACK (192 Presets + Tečaj){" "}
        <span role="img" aria-label="Click Here">
          ➡️
        </span>
      </Link> */}
    </div>
  )
}

export default Grid
