import React from "react"
import { useInView } from "react-intersection-observer"

const DynamicWrapper = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  return (
    <section ref={ref}>
      {inView ? children : <div className="bg-white blur-lg h-80"></div>}
    </section>
  )
}

export default DynamicWrapper
