import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
  return (
    <div>
      <div className="flex justify-center mb-1">
        <a
          href="https://instagram.com/lilicadez"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../../images/avatar.jpeg"
            alt="Lili Cadez"
            placeholder="blurred"
            loading="lazy"
            width={80}
            height={80}
            layout="fixed"
            imgStyle={{ borderRadius: "100%" }}
            className="rounded-full block shadow-md"
          />
        </a>
      </div>
      <p className="font-medium text-center mb-6">
        Lili Čadež <br />
        Content Creator & UGC Agency Founder
      </p>
    </div>
  )
}

export default Header
