import React from "react"
// import loadable from "@loadable/component"

// Components
import DynamicWrapper from "../components/dynamicWrapper"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/links/header"
import Grid from "../components/links/grid"
import Socials from "../components/links/socials"
// const Image = loadable(() => import("../components/links/image"))

const Links = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <Seo lang="sl" title="Links" />
      <section className="max-w-screen-sm mx-auto px-4 py-4 sm:py-16">
        <Header />
        <Grid />
        {/* <DynamicWrapper>
          <Image />
        </DynamicWrapper> */}
        <DynamicWrapper>
          <Socials />
        </DynamicWrapper>
      </section>
    </Layout>
  )
}
export default Links
